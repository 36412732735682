<template>

   <div :class="[$store.state.background_dark ? 'background_dark' : 'background_dark_none']"  class="h-screen w-full fixed top-0 left-0 right-0 z-20"></div>

    
    <div class="wrapper mx-auto">

        <div class="swiffy-slider slider-item-ratio slider-item-ratio-4x3 slider-nav-autoplay slider-nav-arrow slider-nav-autoplay" :class="{'slider-item-reveal': $store.state.sliders.length > 1}">
            <ul class="slider-container">
                <li @click="selectSlider(s.id)" v-for="s, i in $store.state.sliders" v-bind:key="i">
                    <img :src="s.image" alt="" style="object-position: center; object-fit: cover; border-radius: 5px;"/> 
                </li>
            </ul>
        </div>

        <div id="navEl" class="sticky top-0 py-3  bg-white z-10" >
            <div class="flex justify-between mb-2">
                <p class="text-lg font-medium">{{$t('word39')}}</p>
                <p @click="showCategories()">{{$t('word40')}}</p>
            </div>
            <div class="swiffy-slider">
                <ul class="slider-container  slider-item-show2 slider-item-reveal slider-item-nogap">
                    <li v-for="c, i in $store.state.categories_nav" v-bind:key="i" class="pr-2">
                        <button @click="selectCategory(c.id)" type="button" class="whitespace-nowrap text-gray-900 bg-white border border-zinc-200 w-full p-2 font-medium rounded-lg" :class="{'bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400 text-white': c.active}" style="overflow: hidden">{{ c.title }}</button>
                    </li>
                </ul>
            </div>
        </div>

        

        <div v-for="c, i in $store.state.categories" v-bind:key="i" :id="'c'+c.id">
            <div class="flex justify-between items-center mb-3 mt-4">
                <p class="text-2xl font-medium">{{ c.title }}</p>
                <div v-if="c.products.length > 2">
                    <small v-if="c.active" @click="c.active = false" class="flex justify-between items-center"> 
                       
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 pr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
</svg>

                        {{$t('word148')}}
                    </small>
                    <small v-else @click="c.active = true" class="flex justify-between items-center">
                        
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 pr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
</svg>

                         {{$t('word149')}}
                        </small>
                </div>
            </div>
            <div :class="[c.active ||  c.products.length < 3 ? '' : 'swiffy-slider']">
                <ul :class="[c.active || c.products.length < 3 ? 'grid gap-3 grid-cols-2' : 'slider-container slider-item-show2 slider-item-reveal py-2']" >
                    <div @click="selectProduct(p.id)" v-for="p, i in c.products" v-bind:key="i" class="shadow-md  rounded-md">
                    <img :src="p.image_min" style="height: 200px; object-fit: cover; object-position: center" class="w-full rounded-t-lg object-cover object-center" :class="{'grayscale': p.stoplist}" alt="">
                    <div class="px-2 py-3">
                        <p class="text-md whitespace-nowrap overflow-hidden">{{ p.title }}</p>
                        <p class="text-xs  text-gray-500 whitespace-nowrap overflow-hidden">{{ p.description }}</p>
                        <div v-if="p.stoplist" class="mt-2">
                            <p class="text-sm text-red-700">{{$t('word29')}}</p>
                        </div>
                        <div v-else class="mt-2 flex justify-between items-end">
                            <p class="text-xs">{{ p.show_price }} {{ $store.state.vendor.currency }}</p>
                            <p v-show="$store.state.vendor.dollar" class="text-xs text-gray-400">{{ (p.price / $store.state.vendor.exchange).toFixed(2)  }}$</p>
                        </div>
                    </div>
                    </div>
                </ul>
            </div>
        </div>

        <br>
        <div class="my-5 text-center">
            <div class="flex justify-center items-center">
                <img src="@/assets/fav.png" width="20px;"> 
                <p class="text-sm">{{$t('word143')}}</p>
            </div>
            <a href="https://instagram.com/yola.menu" class="text-blue-600">{{$t('word144')}}</a>
        </div>

        <br><br><br><br>
       
        <div @click="showAction()" class="p-2 bg-white z-30 rounded  bg-gradient-to-r from-blue-600 via-blue-500 to-blue-500  text-white flex flex-col justify-center items-center" style="position: fixed; bottom: 75px; right: 15px">
            <svg id="bell" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
            </svg>
            <small class="mt-1" style="font-size: 8px">{{$t('word146')}}</small>
        </div>

    </div>

  
    
    <Navigator />
    <Categories />
   
    <div  class="rounded-t-lg bg-white fixed w-full z-30 pt-3"  :class="{'modal_view' : $store.state.action_open }">
        <div class="flex justify-center py-2"><div style="height: 3px; width:60px; background-color: #999"></div></div>
        <div class="wrapper mx-auto">
            <div class="flex justify-between items-center">
                <p class="text-lg font-medium">{{$t('word146')}}</p>
                <svg @click="$store.commit('toggleActionOpen')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666" class="w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
            </div>
            <div class="mt-4">
                <button @click="pageFeedback()" type="button" class="w-full shadow-sm text-gray-900 bg-white text-lg border border-zinc-200 m-1 p-3 font-medium rounded-lg flex justify-between items-center">
                    <span>{{$t('word3')}}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" /></svg>
                </button>
            </div>
            <br>
        </div>
    </div>
</template>

<script>

import  Navigator  from '@/components/Navigator.vue'
import  Categories  from '@/components/Categories.vue'


export default ({
    components: { Navigator, Categories},
    data() {
        return {
            background_dark: false,
        }
    },
    mounted() {
        localStorage.getItem('scroll') ? window.scrollTo(0, localStorage.getItem('scroll')) : ""
    },
    methods: {
        selectCategory(id) {
            this.$store.commit("selectCurrentCategory", id); 
            document.querySelector('#c' + id).scrollIntoView({ behavior: "smooth" });
            window.scrollTo(0, window.pageYOffset + document.querySelector('#c' + id).getBoundingClientRect().top - 110)
        },
        showCategories() {
            this.$store.commit("toggleCategoryOpen"); 
        },
        showAction() {
            this.$store.commit("toggleActionOpen"); 
        },
        pageFeedback() {
            this.$store.commit("toggleActionOpen"); 
            this.$router.push({ name: 'feedback' })
        },
        selectSlider(id) {
            this.$store.commit("setCurrentSlider", id); 
            this.$router.push({ name: 'slider', params: { slider: id },  })
        },
        selectProduct(id) {
            localStorage.setItem('scroll', window.pageYOffset)
            this.$store.commit("setCurrentProduct", id);  
            this.$router.push({ name: 'product', params: { product: id },  })
            this.$store.state.product_size = {
                id: id,
                size: 0,
                title: this.$store.state.product.title,
                price: this.$store.state.product.price,
                show_price: this.$store.state.product.show_price,
            }
        }

    }
    
})
</script>   

<style lang="scss">
    #bell {
        animation: bell 5s ease-in-out infinite;
    }
    @keyframes bell {
        0% {
            transform: rotate(0deg);
        }
        10% {
            transform: rotate(30deg);
        }
        20% {
            transform: rotate(-30deg);
        }
        30% {
            transform: rotate(30deg);
        }
        40% {
            transform: rotate(-30deg);
        }
        50% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(0);
        }
    }
</style>